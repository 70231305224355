import React from "react";
import './TermsAndConditions.css';
import '../AboutPage/AboutPage.css'

const TermsAndConditions = () => {
    const termsAndConditions = {
        heading: "Terms and Conditions for Padmashali Global",
        latestUpdate: "Last Updated: [06/02/2025]",
        welcomeText: "Welcome to the Padmashali Global mobile application ('App'). By using this App, you agree to abide by the Terms and Conditions ('Terms of Use') as set out below.",
        welocomeText2: "These Terms govern your use of our services and content and establish a legal agreement between you and Ro-One Technology Private Limited.",
        point1Heading: "1. Definitions",
        point1Text1: "'We', 'Our', 'Firm', 'Us': Refers to the Padmashali Global App and its creators.",
        point1Text2: "'You', 'User', 'Your', 'Yourself': Refers to any natural or legal individual using this App who has the capacity to enter into legally binding contracts under applicable law.",
        point1Text3: " 'Third Parties': Any entity or individual other than Padmashali Global and the User.",
        point2Heading: "2. General Terms",
        point2Text1: "The section headings in these Terms are for reference only and have no legal or contractual effect.",
        point2Text2: "These Terms, along with our Privacy Policy,",
        point2Text3: "constitute a binding agreement between you and Padmashali Global.",
        point2Text4: " By using our App, you accept and agree to be bound by these Terms and the Privacy Policy.",
        point2Text5: "If you do not agree with any part of these Terms, you should discontinue using the App immediately.",
        point2Text6: "Your continued use of the App signifies your acceptance of any changes made to these Terms.",
        point3Heading: "3. Application Overview",
        point3Text1: "Padmashali Global is an online platform created for members of the Padmashali community",
        point3Text2: "to connect, share information, and engage with community resources.",
        point3Text3: "This App may include features such as forums, news updates, event information, and other community-oriented content.",
        point4Heading: '4. Registration',
        point4Text1: "To access certain features, Users must register with accurate information,",
        point4Text2: "including their name, phone number, and other relevant details. By registering,",
        point4Text3: "you agree to provide accurate and current information.",
        point4Text4: "Users who fail to comply may face suspension or termination of their accounts.",
        point5Heading: "5. Eligibility",
        point5Text1: "By using this App, you represent and warrant that you are of legal age and possess the authority to enter into legally binding contracts.",
        point5Text2: "Minors using the App must have the consent and supervision of a legal guardian,",
        point5Text3: "and their actions are assumed to be guided by the guardian.",
        point5Text4: "You agree to use the App in compliance with all applicable local, state, national, and international laws.",
        point6Heading: "6. Content",
        point6Text1: "All materials, including text, graphics, User interfaces, visual interfaces,",
        point6Text2: "photos, trademarks, logos, and artwork (collectively 'Content'), are either generated by us or provided by Users and third parties.",
        point6Text3: "While we strive to maintain the integrity and accuracy of Content, Padmashali Global holds no liability for any discrepancies,",
        point6Text4: "inaccuracies, or errors in Content provided by Users or third parties.",
        point6Text5: "All Content is protected by copyright and other intellectual property laws.",
        point6Text6: "You are granted a limited, non-transferable license to use the Content solely for personal, non-commercial use.",
        point6Text7: "Unauthorized use of Content may result in suspension or termination of your account.",
        point7Heading: "7. User Responsibilities",
        point7SubHeading: "Account Security: ",
        point7Text1: "You are responsible for maintaining the security of your account information and agree to notify us immediately of any unauthorized use of your account.",
        point7SubHeading2: "Content Responsibility:",
        point7Text2: "You acknowledge and agree to be solely responsible for the accuracy, authenticity, and legality of any Content you post or share on the App.",
        point7SubHeading3: "Prohibited Actions:",
        point7Text3: "Users are prohibited from posting defamatory, obscene, or illegal content. Padmashali Global reserves the right to suspend or",
        point7Text4: " terminate accounts violating this policy and to report such violations to relevant authorities if required by law.",
        point8Heading: "8. Amendments and Modifications",
        point8Text1: "Padmashali Global reserves the right to modify or amend these Terms without prior notice.",
        point8Text2: " Continued use of the App after any modifications implies acceptance of the new Terms.",
        point8Text3: " If you do not agree with any changes, you should discontinue use of the App immediately.",
        point9Heading: "9. Third-Party Services",
        point9Text1: "This App may include links to or integrate third-party services and websites. We hold no responsibility for the practices, policies,",
        point9Text2: " or content of these third-party services and encourage you to review their policies before engaging with them.",
        point10Heading: "10. Intellectual Property",
        point10Text1: "All intellectual property rights, including trademarks, copyrights, and patents related to the App and its Content, belong to Padmashali Global or its licensors. Unauthorized use, reproduction, or distribution of Content is prohibited.",
        point11Heading: "11. Limitation of Liability",
        point11Text1: "Padmashali Global, its directors, employees, and affiliates are not liable for any direct, indirect, incidental, consequential, or punitive damages arising from:",
        point11Text2: "Use or inability to use the App.",
        point11Text3: "Unauthorized access to or alteration of your Content.",
        point11Text4: "Any content or conduct of a third party on the App.",
        point12Heading: "12. Indemnity",
        point12Text1: "By agreeing to these Terms, you indemnify Padmashali Global and its affiliates from any claims, damages, losses, or expenses, including legal fees, arising from:",
        point12Text2: "Your use or misuse of the App.",
        point12Text3: "Your violation of these Terms.",
        point12Text4: "Any infringement by you of a third party’s rights.",
        point13Heading: "13. Termination",
        point13Text1: "We reserve the right to terminate or suspend access to your account at any time, without prior notice, for any reason, including violation of these Terms.",
        point13Text2: " Termination will result in the deletion of all Content associated with your account",
        point14Heading: "14. Governing Law",
        point14Text1: " These Terms are governed by the laws of India, and any disputes shall be subject to the exclusive jurisdiction of the courts located in Ongole, Andhra Pradesh.",
        point15Heading: "15. No Refund Policy",
        point15Text1: "Please note that there are no refunds for donations or account creation fees. All payments are onetime only and final.",
        point16Heading: "16. Contact Information",
        point16SubText: "If you have questions about these Terms, please contact us at:",
        point16CompanyName: "Ro-One Technology Private Limited,",
        point16CompanyLocation: "Gundlapalli, Ongole, Andhra Pradesh",
        point16CompanyEmail: "Email:  padmashaliglobaltrust@gmail.com",
    }
    return (
        <div className="terms-container">
            {/* Main Heading */}
            <h1>{termsAndConditions.heading}</h1>
            <p><strong>{termsAndConditions.latestUpdate}</strong></p>

            {/* Welcome Text */}
            <p>{termsAndConditions.welcomeText}</p>
            <p>{termsAndConditions.welocomeText2}</p>

            {/* Sections */}
            <h2>{termsAndConditions.point1Heading}</h2>
            <p>{termsAndConditions.point1Text1}</p>
            <p>{termsAndConditions.point1Text2}</p>
            <p>{termsAndConditions.point1Text3}</p>

            <h2>{termsAndConditions.point2Heading}</h2>
            <p>{termsAndConditions.point2Text1}</p>
            <p>{termsAndConditions.point2Text2} {termsAndConditions.point2Text3}</p>
            <p>{termsAndConditions.point2Text4}</p>
            <p>{termsAndConditions.point2Text5}</p>
            <p>{termsAndConditions.point2Text6}</p>

            <h2>{termsAndConditions.point3Heading}</h2>
            <p>{termsAndConditions.point3Text1}</p>

            <h2>{termsAndConditions.point4Heading}</h2>
            <p>{termsAndConditions.point4Text1}</p>

            <h2>{termsAndConditions.point5Heading}</h2>
            <p>{termsAndConditions.point5Text1}</p>

            <h2>{termsAndConditions.point6Heading}</h2>
            <p>{termsAndConditions.point6Text1}</p>

            <h2>{termsAndConditions.point7Heading}</h2>
            <h3>{termsAndConditions.point7SubHeading}</h3>
            <p>{termsAndConditions.point7Text1}</p>

            <h2>{termsAndConditions.point8Heading}</h2>
            <p>{termsAndConditions.point8Text1}</p>

            <h2>{termsAndConditions.point9Heading}</h2>
            <p>{termsAndConditions.point9Text1}</p>

            <h2>{termsAndConditions.point10Heading}</h2>
            <p>{termsAndConditions.point10Text1}</p>

            <h2>{termsAndConditions.point11Heading}</h2>
            <p>{termsAndConditions.point11Text1}</p>

            <h2>{termsAndConditions.point12Heading}</h2>
            <p>{termsAndConditions.point12Text1}</p>

            <h2>{termsAndConditions.point13Heading}</h2>
            <p>{termsAndConditions.point13Text1}</p>

            <h2>{termsAndConditions.point14Heading}</h2>
            <p>{termsAndConditions.point14Text1}</p>

            <h2>{termsAndConditions.point15Heading}</h2>
            <p>{termsAndConditions.point15Text1}</p>

            <h2>{termsAndConditions.point16Heading}</h2>
            <p>{termsAndConditions.point16SubText}</p>
            <p>{termsAndConditions.point16CompanyName}</p>
            <p>{termsAndConditions.point16CompanyLocation}</p>
            <p>{termsAndConditions.point16CompanyEmail}</p>
        </div>
    );
}

export default TermsAndConditions;
