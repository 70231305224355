import React from "react";
import "./AboutPage.css";

const AboutPage = () => {
    return (
        <div className="about-us-container">
            <h1 className="about-heading">About Padmashali Community</h1>
            <p className="about-content">
                The **Padmashali** community is one of the prominent and ancient weaving communities of India, primarily known for its rich tradition in textile and handloom industries. The Padmashalis are believed to be the descendants of sage Markandeya, who, according to mythology, was instructed by Lord Shiva to create the art of weaving. This art was passed down through generations, making the Padmashalis highly skilled weavers renowned for their craftsmanship.

                ### Historical Origins
                The origin of the Padmashali community dates back thousands of years. According to historical records, the community’s name is derived from two Sanskrit words, "Padma" meaning lotus, and "Shali" meaning weaver. It is said that Lord Shiva created the Padmashalis to weave garments for the gods. The community traditionally specialized in weaving cotton and silk sarees, especially in southern India.

                The Padmashalis have a deep connection with the Vedic era, where they were acknowledged for their expertise in creating beautiful fabrics. The community primarily resided in the regions that are now Andhra Pradesh, Telangana, Tamil Nadu, Karnataka, and Maharashtra. Over the centuries, Padmashalis migrated to various parts of India, bringing their skills and cultural heritage to different regions.

                ### Contribution to Textile Industry
                The Padmashalis have made significant contributions to India’s textile industry. The community's weaving techniques are unique and have been passed down through generations, preserving the art form. They are renowned for creating intricate designs, vibrant patterns, and high-quality fabrics. The traditional sarees woven by Padmashalis are highly sought after and are often adorned by women during festivals, weddings, and other auspicious occasions.

                The art of weaving practiced by the Padmashalis involves handlooms, which are operated manually without the use of electricity. This not only preserves the traditional methods but also ensures that the fabrics are of superior quality. The community is also known for its expertise in dyeing fabrics using natural colors, making their products eco-friendly and sustainable.

                ### Cultural Significance
                The Padmashalis follow a rich cultural tradition that is deeply rooted in the ancient Vedas and Puranas. They are primarily followers of **Sanatana Dharma (Hinduism)** and are known for their devotion to various deities like Lord Venkateswara, Goddess Lakshmi, and Lord Shiva. Festivals like **Diwali, Sankranti, Ugadi**, and **Dasara** are celebrated with great enthusiasm and devotion.

                Padmashalis place a strong emphasis on family values, social cohesion, and community welfare. Traditional ceremonies like weddings and religious rituals are celebrated with great pomp, showcasing the rich cultural heritage of the community. Music, dance, and art also play a significant role in their cultural life, with the community often organizing cultural events to preserve and promote their heritage.

                ### Religious Beliefs and Practices
                The Padmashali community is known for its strong religious beliefs and adherence to rituals. Many Padmashalis are followers of **Sri Vaishnavism** and **Shaivism**, and they observe a strict code of conduct when it comes to religious practices. Temples dedicated to various deities serve as central places of worship and community gatherings.

                The community also observes several festivals with devotion, such as:
                - **Sri Rama Navami**: Celebrating the birth of Lord Rama.
                - **Varalakshmi Vratam**: Dedicated to Goddess Lakshmi for prosperity and well-being.
                - **Vinayaka Chavithi**: Honoring Lord Ganesha as the remover of obstacles.

                ### Social Structure and Values
                The Padmashalis have traditionally followed a structured social system, where the community elders play a significant role in decision-making processes. The elders are respected for their wisdom, and their guidance is sought on matters related to family, marriage, and social issues. The community has a strong tradition of helping one another, which is evident in their cooperative efforts during festivals, social gatherings, and other community events.

                One of the key values upheld by the Padmashalis is **education**. The community believes in the power of education and encourages its younger members to pursue academic excellence. Over the years, many Padmashalis have branched out into various fields, including medicine, engineering, technology, and business, while still maintaining a connection to their traditional roots.

                ### Modern-Day Padmashalis
                While the Padmashali community has preserved its traditional weaving practices, many members have diversified into other professions due to the changing socio-economic landscape. With the advent of modernization, handloom weaving faced challenges from mechanized textile production. However, the community has adapted by modernizing its techniques and exploring new markets.

                In recent years, the younger generation of Padmashalis has taken significant steps to revive the traditional handloom industry by promoting their products globally through online platforms. This has helped in not only preserving the heritage but also creating a sustainable livelihood for artisans.

                ### Challenges and Future Outlook
                Despite their contributions to the textile industry, the Padmashali community faces several challenges, such as declining demand for handloom products due to competition from machine-made fabrics. Additionally, the younger generation is moving away from traditional crafts in search of better job opportunities.

                To counter these challenges, several organizations and initiatives have been launched to promote handloom products and provide support to weavers. The Indian government has also recognized the significance of handloom weaving and has taken steps to provide subsidies, training, and marketing assistance to artisans.

                The future of the Padmashali community lies in striking a balance between preserving traditional crafts and embracing modern technology. By promoting their unique handloom products and exploring international markets, the Padmashalis can continue to thrive while keeping their heritage alive.

                ### Conclusion
                The Padmashali community, with its rich history and cultural legacy, continues to play an important role in India's socio-cultural landscape. Their dedication to preserving the art of weaving, coupled with their adaptability to changing times, is a testament to their resilience. As the community looks towards the future, it strives to uphold its values, traditions, and contributions to India's cultural and economic fabric.

                In a rapidly changing world, the Padmashalis remain proud custodians of their heritage, embracing modern advancements while holding steadfast to their roots. By preserving their traditions and passing them on to future generations, the Padmashali community ensures that their legacy will continue to inspire and enrich the world.
            </p>
        </div>
    );
};

export default AboutPage;
