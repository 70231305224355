import React from 'react';
import "./AboutPage.css";

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <h4 className="about-us-title">About us</h4>
      <p className="about-us-text">
        Welcome to Padmashali Global, a vibrant and inclusive platform dedicated to the Padmashali community. Our mission is to connect members of the Padmashali community from all over the world, offering a space where we can come together, share knowledge, and foster growth. Whether you're looking for career opportunities, seeking to get involved in community events, or searching for your life partner, Padmashali Global is here to serve you.
        <br /><br />
        Padmashali Global provides a variety of services designed to cater to the diverse needs of our community members:
        <ul className="about-us-list">
          <li><strong>News and Updates:</strong> Stay informed with the latest news and updates related to the Padmashali community. We aim to keep you connected to what's happening, both globally and locally.</li>
          <li><strong>Events:</strong> Join in on exciting community events, from social gatherings to cultural celebrations. We create opportunities for networking, learning, and celebration.</li>
          <li><strong>Job Listings and Hiring:</strong> Our platform helps job seekers and employers connect. Whether you're searching for job opportunities or looking to hire, Padmashali Global is your go-to resource.</li>
          <li><strong>Donations:</strong> Give back to the community through our donation programs. We encourage support for various charitable initiatives, helping those in need within our community.</li>
          <li><strong>Elections for Community Roles:</strong> Participate in elections for community leadership roles. Padmashali Global provides a transparent platform for electing individuals to represent the community and make important decisions.</li>
          <li><strong>Matrimony:</strong> Padmashali Global offers a secure and trustworthy space for individuals seeking life partners within our community. Our matrimony section helps people connect based on shared values, interests, and cultural alignment. Whether you're looking for companionship, love, or marriage, Padmashali Global offers a meaningful platform to explore relationships that are built on mutual respect and shared heritage.</li>
        </ul>
        Our vision is to create a thriving, interconnected digital space where Padmashalis around the world can come together to celebrate our rich culture, traditions, and values. Padmashali Global is more than just an app—it's a movement that empowers and connects the Padmashali community to grow and thrive.
        <br /><br />
        Join us today and become part of the global Padmashali family. Together, we can build a stronger, more united community that flourishes on collaboration, respect, and shared success.
        <br /><br />
        Thank you for being a valued member of Padmashali Global. We're excited to have you with us on this journey!
      </p>
    </div>
  );
}

export default AboutUs;
